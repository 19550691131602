<style lang="scss" scoped>
    @import '~scss/variables';
    @import '~scss/mixins';
    .text-box {
        max-width: 100%;
        label {
            vertical-align: top;
            width: 100px;
        }
        &__container {
            max-width: 100%;
            display: inline-block;
            textarea {
                background-color: white;
                resize: horizontal;
                overflow-y: hidden;
                border: 1px solid $border_grey;
                padding: spacing(xxs) spacing(xs);
                &:focus {
                    box-shadow: 0px 0px 5px #7FD7FF;
                }
            }
        }
        &__count {
            font-size: fz(xs);
            color: $grey_mid_1;
        }
    }
</style>
<template>
    <div class="text-box">
        <label :for="id" v-if="label" :class="hasValue">{{label}}</label>
        <div class="text-box__container">
            <textarea
                :id="id"
                :class="hasValue"
                :value="value"
                :maxlength="maxlength"
                :cols="cols"
                :rows="rows"
                :spellcheck="spellcheck"
                @input="onInput"
                @focus="hasValue=''"
                @blur="hasValue = value ? '' : ' empty'">
            </textarea>
            <div class="text-box__count block-xs--xxs" v-if="maxlength || showCount">
                {{count}}<span v-if="maxlength">/{{maxlength}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TextBox',
    data: function() {
        return {
            id: `text-box-${Math.random().toString(36).substring(7)}`,
            hasValue: this.value ? '' : ' empty',
            count: this.value ? this.value.length : 0,
        }
    },
    watch: {
        value(value) {
            if (value === '') {
                this.hasValue = ' empty'
            } else {
                this.hasValue = ''
            }
            this.count = value.length
        },
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        label: {
            type: String,
        },
        maxlength: {
            type: [Number, String],
        },
        showCount: {
            type: Boolean,
        },
        rows: {
            type: [String, Number],
        },
        cols: {
            type: [String, Number],
        },
        spellcheck: {
            type: [String, Boolean],
        },
    },
    methods: {
        resize() {
            let textarea = document.getElementById(this.id)
            textarea.style.minHeight = '1px'
            textarea.style.minHeight = `${textarea.scrollHeight}px`
        },
        onInput(e) {
            this.resize()
            this.$emit('input', e.target.value)
        },
    },
    mounted() {
        this.resize()
    },
}
</script>
