var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "EditingModal",
    { staticClass: "add-image-modal", attrs: { size: _vm.size } },
    [
      _c(
        "AlertDialog",
        {
          attrs: {
            title: _vm.title,
            onExit: () => this.$emit("close"),
            actions: _vm.actions,
          },
        },
        [
          _vm.stepOne
            ? _c(
                "v-container",
                { staticClass: "image-container pa-0" },
                _vm._l(_vm.pairs, function (pair) {
                  return _c(
                    "v-row",
                    { key: pair[0], staticClass: "ma-0" },
                    _vm._l(pair, function (imageKey) {
                      return _c(
                        "v-col",
                        {
                          key: imageKey,
                          staticClass: "box align justify",
                          attrs: { sm: "6" },
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "image-card elevation-0" },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "pl-0 pr-0 justify-center" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.imageInfo[imageKey].formString)
                                  ),
                                ]
                              ),
                              _c("v-img", {
                                attrs: {
                                  src: _vm.imageForKey(imageKey),
                                  "aspect-ratio":
                                    _vm.imageInfo[imageKey].aspectRatio,
                                  height: 120,
                                  width: 240,
                                  contain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onImageClick(imageKey)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "placeholder",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "fill-height ma-0",
                                              attrs: {
                                                align: "center",
                                                justify: "center",
                                              },
                                            },
                                            [
                                              _c("v-progress-circular", {
                                                attrs: {
                                                  indeterminate: "",
                                                  color: "#93BD20",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c(
                                "v-card-text",
                                {
                                  staticClass: "text-center image-label",
                                  style: { maxWidth: 240 + "px" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.imageInfo[imageKey].platformString
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.stepTwo
            ? _c("ImageLoader", {
                attrs: { imageInfo: _vm.selectedImageInfo },
                on: { crop: _vm.onCrop },
              })
            : _vm._e(),
          _vm.stepThree
            ? _c("div", { attrs: { "text-xs-center": "" } }, [
                _vm._v(
                  "Your images are syncing with the server, this process may take up to 5 minutes. While the images are syncing, please do not refresh the page."
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }