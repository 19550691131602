<template>
  <div class="variation-builder block-xs--md-bottom row no-gutters">
    <template v-if="value.length">
      <div class="variation-builder__table col-12">
        <div>
          <div class="line">
            <div v-if="bespoke" class="variation-builder__table-data"></div>
            <div
              v-for="prop in struct"
              :key="prop.display"
              :style="{ flex: prop.size || 1 }"
              class="variation-builder__table-data"
            >
              {{ prop.display }}
            </div>
          </div>
        </div>

        <div ref="sortableList">
          <div v-for="(variation, i) in localItems" :key="variation.inventoryItemSubID" class="line">
            <div v-if="bespoke" class="variation-builder__table-data"><div class="icon icon--handle"/></div>
            <div
              v-for="prop in struct"
              :class="{ small }"
              :key="prop.name"
              :style="{ flex: prop.size || 1 }"
              class="variation-builder__table-data"
            >
              <template v-if="prop.name">
                <form-input
                  v-model="variation[prop.name]"
                  @input="onNameChanged(i)"
                  :disabled="disabled && !canForceEdit(prop.name)"
                  :bare="true"
                  :full="true"
                  style="width: 100%"
                  spellcheck="true"
                />
              </template>

              <template v-else>
                <component :is="prop.render(variation, i)" />
              </template>
            </div>

            <div class="variation-builder__delete-container">
              <div
                v-if="value.length > 1 || !integrated"
                class="variation-builder__button icon icon--remove block-xs--xs-left"
                @click="removeVariation(variation)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <p>
        There does not appear to be any {{ entityName }}s for this item. Click
        below to add one.
      </p>
    </template>

    <text-button
      class="block-xs--md-top col-12"
      type="add"
      @click.native="addVariation"
      >Add {{ entityName }}</text-button
    >
  </div>
</template>

<script>
import FormInput from "./cleverly/FormInput";
import TextButton from "./text_button";
import Sortable from "sortablejs";

export default {
    name: "VariationTable",
    data() {
        return {
            localItems: this.value
        }
    },
    // Components

    components: {
        FormInput,
        TextButton,
    },

    // Props

    props: {
        value: {
            type: Array,
            required: true,
        },

        struct: {
            type: Array,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        forceEditable: {
            type: Array,
            default: () => [],
        },

        entityName: {
            type: String,
            default: "variation",
        },

        small: {
            type: Boolean,
            default: false,
        },
        productID: {
            type: String,
            default: "1",
        },
        integrated: {
            type: Boolean,
            default: false,
        },
        bespoke: {
            type: Boolean,
            default: false,
        }
    },

    // Methods

    methods: {
        addVariation() {
            this.$emit("add");
            this.handleChange();
        },

        removeVariation(index, payload) {
            this.$emit("remove", index, payload);
            this.handleChange();
        },

        canForceEdit(key) {
            return this.forceEditable.indexOf(key) !== -1;
        },
        onNameChanged(index) {
            let sub = this.value[index];
            this.$emit("subNameChanged", sub);
        },
        dragReorder({ oldIndex, newIndex }) {
            const movedItem = this.localItems.splice(oldIndex, 1)[0];
            this.localItems.splice(newIndex, 0, movedItem);
            this.localItems = this.localItems.map((el, index) => {
                return {
                    ...el,
                    inventoryOrder: index
                }
            })
            this.$emit("reorder", this.localItems)
            this.handleChange();
        },
        handleChange() {
            this.installSortable();
        },
        installSortable() {
            if (!this.bespoke) return
            this.$nextTick(() => {
                if (this.$refs.sortableList === undefined) return;
                if (this.sortable != undefined) {
                    this.sortable.destroy();
                }
                this.sortable = new Sortable(this.$refs.sortableList, {
                    handle: ".icon--handle",
                    onStart: () => {},
                    onEnd: this.dragReorder,
                });
            });
        },
    },
    mounted() {
        this.installSortable();
    },
    watch: {
        value(newValue, oldValue) {
            this.localItems = newValue;
            this.installSortable()
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.line {
  position: relative;
  display: flex;

  & > * {
    flex: 1;
  }
}

.variation-builder {
  padding-right: 20px;

  .icon {
    cursor: grab;
  }

  &__table {
    border: 1px solid $border_grey;
    border-right: 0;
    border-bottom: 0;

    &-data {
      display: flex;
      justify-content: center;
      background-color: #fff;
      padding: spacing(xs);
      border-bottom: 1px solid $border_grey;
      border-right: 1px solid $border_grey;
      text-align: center;

      & >>> input {
        width: 100%;
      }

      &.small {
        padding: 0;

        input {
          max-width: 120px;
        }
      }
    }
  }

  &__button {
    cursor: pointer;
  }

  &__table-data,
  &__delete-container {
    display: flex;
    align-items: center;
  }

  &__delete-container {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
  }
}
</style>
