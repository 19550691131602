var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { staticClass: "product-form" },
    [
      _vm.existsOnProduct("inventoryItemName") ||
      (_vm.existsOnProduct("inventoryItemSubs") &&
        _vm.value.inventoryItemSubs.length) ||
      _vm.existsOnProduct("inventoryItemTitles") ||
      _vm.existsOnProduct("inventoryItemDescription") ||
      _vm.existsOnProduct("healthInformation") ||
      _vm.existsOnProduct("additionalTurnaround") ||
      _vm.existsOnProduct("bAlcohol")
        ? _c("line-item", { staticClass: "row no-gutters" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _vm.existsOnProduct("inventoryItemName")
                  ? _c("form-input", {
                      staticClass: "product-form-control large block-xs--xs",
                      attrs: { label: "Name", spellcheck: "true" },
                      model: {
                        value: _vm.value.inventoryItemName,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "inventoryItemName", $$v)
                        },
                        expression: "value.inventoryItemName",
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _vm.existsOnProduct("inventoryItemSubs")
                      ? [
                          _vm.integrated ||
                          _vm.value.inventoryItemSubs.length > 1
                            ? [
                                _c("form-input", {
                                  staticClass:
                                    "block-xs--xs-bottom adjacent-form",
                                  attrs: { disabled: true, label: "Price" },
                                  model: {
                                    value: _vm.value.costDisplay,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, "costDisplay", $$v)
                                    },
                                    expression: "value.costDisplay",
                                  },
                                }),
                              ]
                            : _vm.existsOnProduct("inventoryItemSubs") &&
                              _vm.value.inventoryItemSubs.length === 1
                            ? [
                                _vm.value.inventoryItemSubs.length === 1
                                  ? _c("form-input", {
                                      staticClass:
                                        "block-xs--xs-bottom adjacent-form",
                                      attrs: { label: "Price" },
                                      model: {
                                        value:
                                          _vm.value.inventoryItemSubs[0].cost,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.value.inventoryItemSubs[0],
                                            "cost",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "value.inventoryItemSubs[0].cost",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.integrated
                      ? [
                          _vm.existsOnProduct("pos_productID") &&
                          _vm.existsOnProduct("inventoryItemSubs") &&
                          _vm.value.inventoryItemSubs.length === 1
                            ? _c("form-input", {
                                staticClass: "block-xs--xs-bottom",
                                attrs: { label: "Item ID", disabled: "" },
                                model: {
                                  value: _vm.value.pos_productID,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, "pos_productID", $$v)
                                  },
                                  expression: "value.pos_productID",
                                },
                              })
                            : _vm._e(),
                          _vm.existsOnProduct("inventoryItemSubs") &&
                          _vm.value.inventoryItemSubs.length > 1
                            ? _c("form-input", {
                                staticClass: "block-xs--xs-bottom",
                                attrs: {
                                  value: "Multi",
                                  label: "Item ID",
                                  disabled: "",
                                },
                              })
                            : _vm._e(),
                        ]
                      : [
                          _vm.existsOnProduct("inventoryItemSubs") &&
                          _vm.value.inventoryItemSubs.length
                            ? _c("form-input", {
                                staticClass: "block-xs--xs-bottom",
                                attrs: { label: "Item ID" },
                                model: {
                                  value: _vm.value.inventoryItemID,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, "inventoryItemID", $$v)
                                  },
                                  expression: "value.inventoryItemID",
                                },
                              })
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
                _vm.existsOnProduct("inventoryItemSubs")
                  ? [
                      _vm.value.inventoryItemSubs.length === 1
                        ? [
                            _vm.value.inventoryItemSubs.length > 0
                              ? _c("form-input", {
                                  staticClass: "block-xs--xs-bottom",
                                  attrs: { label: "UPC Code" },
                                  model: {
                                    value: _vm.value.inventoryItemSubs[0].sku,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.value.inventoryItemSubs[0],
                                        "sku",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "value.inventoryItemSubs[0].sku",
                                  },
                                })
                              : _vm._e(),
                          ]
                        : [
                            _c("div", { staticClass: "block-xs--xs-bottom" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("UPC Code"),
                              ]),
                              _c("span", { staticClass: "d-inline-block" }, [
                                _vm._v(
                                  "Unavailable due to multiple sizes on item"
                                ),
                              ]),
                            ]),
                          ],
                    ]
                  : _vm._e(),
                _vm.existsOnProduct("inventoryItemDescription")
                  ? _c("text-box", {
                      staticClass: "block-xs--xs-bottom",
                      attrs: {
                        rows: "6",
                        cols: "65",
                        label: "Description",
                        spellcheck: "true",
                      },
                      model: {
                        value: _vm.value.inventoryItemDescription,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "inventoryItemDescription", $$v)
                        },
                        expression: "value.inventoryItemDescription",
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.existsOnProduct("inventoryMainOptionChoice")
        ? _c("line-item", { staticClass: "row no-gutters" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("div", { staticClass: "label block-xs--xs" }, [
                  _vm._v("Modifiers"),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-0 pt-0 block-xs--xs font-weight-norma" },
                  [
                    _vm._v(
                      "Required Modifier Groups will always be display first to the guest, then optional modifier groups."
                    ),
                  ]
                ),
                _vm.filteredModGroups.length
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "block-xs--xs-bottom col-12 col-lg-8 col-xl-6",
                        },
                        [
                          _c("tiny-explorer", {
                            attrs: {
                              children: _vm.filteredModGroups,
                              permissions: _vm.modPermissions,
                              resolveChildStatus: (el) => el.required,
                              onRemoveChild: _vm.handleRemoveModGroup,
                              onRemoveGrandChild: _vm.handleRemoveMod,
                              onEditChild: _vm.handleModGroupEdit,
                              onCheckChild: _vm.handleCheckModGroup,
                              onReorder: _vm.handleReorderModGroups,
                              resolveGrandChildTitle:
                                _vm.modGroupGrandChildTitle,
                              childCheckLabel: _vm.modGroupCheckLabel,
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.canEdit
                  ? _c(
                      "text-button",
                      {
                        staticClass: "block-xs--xs-bottom",
                        attrs: { type: "add" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.toggleModGroupModal.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(" Add "),
                        _vm.filteredModGroups.length
                          ? [_vm._v("another")]
                          : [_vm._v("a")],
                        _vm._v(" Mod Group "),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.modGroupModal
                  ? _c(
                      "editing-modal",
                      { staticClass: "modal", attrs: { size: "lg" } },
                      [
                        _c("editing-modal-header-simple", {
                          attrs: { title: "Create or Select a Mod Group" },
                          on: { closeModal: _vm.toggleModGroupModal },
                        }),
                        _c("add-mod-group", {
                          attrs: {
                            modGroups: _vm.groups,
                            passedModGroup: _vm.editedModGroup,
                            posMods: _vm.posMods,
                            integrated: _vm.integrated,
                            productName: _vm.value.inventoryItemName,
                          },
                          on: {
                            save: _vm.addModGroup,
                            removeMod: function ($event) {
                              return _vm.$emit("removeMod", arguments[0])
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.warningActive
                  ? _c("edit-warning", {
                      attrs: {
                        type: _vm.warningType,
                        effectedItems: _vm.effectedItems,
                        onCancel: _vm.handleEditCancel,
                        onContinue: _vm.handleEditContinue,
                        resolveEffectedTitle: (item) => item.inventoryItemName,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.existsOnProduct("inventoryItemName") ||
      (_vm.existsOnProduct("inventoryItemSubs") &&
        _vm.value.inventoryItemSubs.length) ||
      _vm.existsOnProduct("inventoryItemTitles") ||
      _vm.existsOnProduct("inventoryItemDescription") ||
      _vm.existsOnProduct("healthInformation") ||
      _vm.existsOnProduct("additionalTurnaround") ||
      _vm.existsOnProduct("bAlcohol")
        ? _c("line-item", { staticClass: "row no-gutters" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-2" },
                    [
                      _vm.existsOnProduct("inventoryTitles")
                        ? _c("select-input", {
                            staticClass: "block-xs--xs-bottom category-temp",
                            attrs: {
                              options: _vm.categoryOptions,
                              label: "Category",
                            },
                            model: {
                              value: _vm.category,
                              callback: function ($$v) {
                                _vm.category = $$v
                              },
                              expression: "category",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-2" },
                    [
                      _vm.existsOnProduct("inventoryTitles") &&
                      _vm.subCategoryOptions.length > 0
                        ? _c("select-input", {
                            staticClass: "block-xs--xs-bottom category-temp",
                            attrs: {
                              options: _vm.subCategoryOptions,
                              label: "Subcategory",
                            },
                            model: {
                              value: _vm.subCategory,
                              callback: function ($$v) {
                                _vm.subCategory = $$v
                              },
                              expression: "subCategory",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.existsOnProduct("bAlcohol")
                  ? [
                      _vm.existsOnProduct("bAlcohol")
                        ? _c("toggle", {
                            staticClass: "block-xs--xs-bottom",
                            attrs: { label: "Alcohol" },
                            model: {
                              value: _vm.value.bAlcohol,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "bAlcohol", $$v)
                              },
                              expression: "value.bAlcohol",
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.existsOnProduct("inventoryFeatureItem")
                  ? [
                      _vm.existsOnProduct("inventoryFeatureItem")
                        ? _c("toggle", {
                            staticClass: "block-xs--xs-bottom",
                            attrs: { label: "Featured Item" },
                            model: {
                              value: _vm.value.inventoryFeatureItem,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "inventoryFeatureItem", $$v)
                              },
                              expression: "value.inventoryFeatureItem",
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.existsOnProduct("bEmployeeItem")
                  ? [
                      _vm.existsOnProduct("bEmployeeItem")
                        ? _c("toggle", {
                            staticClass: "block-xs--xs-bottom",
                            attrs: { label: "Employee Only" },
                            model: {
                              value: _vm.value.bEmployeeItem,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "bEmployeeItem", $$v)
                              },
                              expression: "value.bEmployeeItem",
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.existsOnProduct("taxes")
        ? _c("line-item", { staticClass: "row no-gutters" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("span", { staticClass: "label block-xs--xs" }, [
                _vm._v("Tax"),
              ]),
              _c(
                "div",
                { staticClass: "group-inputs taxes block-lg--xs" },
                [
                  _vm._l(_vm.value.taxes, function (tax) {
                    return [
                      _c(
                        "div",
                        { key: tax.description },
                        [
                          _c("form-input", {
                            staticClass:
                              "block-xs--xs-right block-xs--xs-bottom sub-label",
                            attrs: {
                              value: _vm._f("parseTaxRate")(tax.Amount),
                              label: tax.Description + " (%)",
                              type: "number",
                            },
                            on: {
                              change: function ($event) {
                                tax.Amount = arguments[0] / 100
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
      _vm.existsOnProduct("inventoryItemSubs") && this.pos != "tgif"
        ? _c("line-item", { staticClass: "row no-gutters" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("span", { staticClass: "label block-xs--xs" }, [
                _vm._v("Variations"),
              ]),
              _c(
                "div",
                { staticClass: "d-inline-block" },
                [
                  _c("variation-builder", {
                    staticClass: "block-lg--xs",
                    attrs: {
                      value: _vm.sortedSubs,
                      integrated: _vm.integrated,
                      posItems: _vm.posProducts,
                      productId: _vm.value.pos_productID,
                    },
                    on: {
                      remove: _vm.handleVariationDelete,
                      change: _vm.handleVariationsChange,
                    },
                    model: {
                      value: _vm.sortedSubs,
                      callback: function ($$v) {
                        _vm.sortedSubs = $$v
                      },
                      expression: "sortedSubs",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.existsOnProduct("startTimeLocal") &&
      _vm.existsOnProduct("endTimeLocal") &&
      _vm.availability != null
        ? _c("line-item", { staticClass: "row no-gutters" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("span", { staticClass: "label block-xs--xs-top" }, [
                _vm._v("Time"),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "group-inputs block-xs--xs-bottom block-lg--xs-top",
                },
                [
                  _c("time-selection", {
                    attrs: { value: _vm.availability },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("availabilityChange", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.availableTags.length != 0
        ? _c("line-item", [
            _c(
              "div",
              [
                _c("div", { staticClass: "label block-xs--xs" }, [
                  _vm._v("Tags"),
                ]),
                _c("tags", {
                  staticClass: "block-xs--xs-top",
                  attrs: { tags: _vm.availableTags },
                  model: {
                    value: _vm.value.inventoryTags,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "inventoryTags", $$v)
                    },
                    expression: "value.inventoryTags",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.existsOnProduct("inventoryItemAttributeID")
        ? _c("line-item", { staticClass: "row no-gutters" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", [
                _c("div", { staticClass: "label block-xs--md" }, [
                  _vm._v("Attributes"),
                ]),
                _c("div", { staticClass: "group-inputs block-xs--xs" }, [
                  _c("div", { staticClass: "row no-gutters" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        { staticClass: "row no-gutters" },
                        _vm._l(_vm.productAttributes, function (attr) {
                          return _c("toggle", {
                            key: attr,
                            staticClass: "col-5 block-xs--xs-bottom",
                            attrs: {
                              value: _vm.attributeIsChecked(
                                attr.inventoryItemAttributeID
                              ),
                              multiLabel:
                                attr.inventoryItemAttributeDescription,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.toggleAttribute(arguments[0], attr)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.existsOnProduct("imageV2") && _vm.userHasViewPermission("IMAGES")
        ? _c(
            "line-item",
            { staticClass: "row no-gutters" },
            [
              _c("StoreProductEditImageRow", {
                attrs: { images: _vm.productImages, product: _vm.value },
                on: {
                  inventoryItemImageNameChanged:
                    _vm.inventoryItemImageNameChanged,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("line-item", [
        _c(
          "div",
          { staticClass: "row no-gutters align-items-center" },
          [
            _c("ProductPlatformEdit", {
              attrs: { productId: _vm.value.id },
              model: {
                value: _vm.value.platformInventory,
                callback: function ($$v) {
                  _vm.$set(_vm.value, "platformInventory", $$v)
                },
                expression: "value.platformInventory",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }