<template>
  <EditingModal :size="size" class="add-image-modal">
    <AlertDialog :title="title" :onExit="() => this.$emit('close')" :actions="actions">
      <v-container class="image-container pa-0" v-if="stepOne">
        <v-row class="ma-0"  v-for="pair in pairs" :key="pair[0]">
          <v-col
            sm="6"
            class="box align justify"
            v-for="imageKey in pair"
            :key="imageKey"
          >
            <v-card class="image-card elevation-0">
              <v-card-title class="pl-0 pr-0 justify-center">{{ imageInfo[imageKey].formString}}</v-card-title>
              <v-img
                :src="imageForKey(imageKey)"
                :aspect-ratio="imageInfo[imageKey].aspectRatio"
                :height="120"
                :width="240"
                contain
                @click="onImageClick(imageKey)"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="#93BD20"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-text
                class="text-center image-label"
                :style="{maxWidth: 240 + 'px'}"
              >{{ imageInfo[imageKey].platformString}}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <ImageLoader v-if="stepTwo" @crop="onCrop" :imageInfo="selectedImageInfo" />
      <div v-if="stepThree" text-xs-center>Your images are syncing with the server, this process may take up to 5 minutes.  While the images are syncing, please do not refresh the page.</div>
    </AlertDialog>
  </EditingModal>
</template>

<script>
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import ImageLoader from "components/imagemanager/image_loader.vue";

import {
    fullUrlForImageValue,
    aspectRatioForImageKey,
    imagesPageDisplayStringForImageKey,
    dimensionsForImageKey,
    missingImageForImageKey,
    imageUploadInfo,
    generateImageFileName
} from "helpers/images.js";

import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            step: 1,
            selectedImageKey: undefined,
            selectedImageBase64: undefined,
            selectedImageFileName: undefined,
            pairs: [
                ["product_1_1", "product_16_9"],
                ["featured_9_2", "launch_1_1"],
                ["launch_9_16", "img_asset_384x216"]
            ],
            dirtyImages: {},
            result: undefined
        };
    },
    components: {
        EditingModal,
        AlertDialog,
        ImageLoader
    },
    props: {
        product: {
            type: Object
        },
        images: {
            type: Array
        }
    },
    computed: {
        size() {
            return this.stepThree ? "sm" : "md"
        },
        title() {
            return this.stepThree ? "Images updating" : "Add or Edit Photo"
        },
        actions() {
            let initial = this.stepThree
                ? []
                : [
                    {
                        type: "faded",
                        display: this.stepOne ? "Cancel" : "Back",
                        run: this.cancel
                    }
                ];
            return initial.concat({
                type: this.doneEnabled ? "green" : "faded",
                display: "Done",
                noIcon: true,
                disabled: !this.doneEnabled,
                run: this.done
            });
        },
        imageInfo() {
            return imageUploadInfo();
        },
        stepOne() {
            return this.step == 1;
        },
        stepTwo() {
            return this.step == 2;
        },
        stepThree() {
            return this.step == 3;
        },
        doneEnabled() {
            if (this.stepOne && Object.keys(this.dirtyImages).length) {
                return true;
            } else if (this.stepTwo && this.selectedImageBase64) {
                return true;
            }
            return false;
        },
        selectedImageInfo() {
            if (this.selectedImageKey == undefined) return {};
            return this.imageInfo[this.selectedImageKey];
        },
        ...mapGetters(["store"])
    },
    methods: {
        imageForKey(key) {
            if (this.dirtyImages[key] != undefined)
                return this.dirtyImages[key].imageBase64;

            if (this.images == undefined) return this.imageInfo[key].missingImage;
            let image = this.images.find(el => el.imageType == key);

            if (image == undefined) return this.imageInfo[key].missingImage;

            return image.imageUrl;
        },
        onImageClick(key) {
            this.selectedImageKey = key;
            this.step = 2;
        },
        onCrop({ fileName, imageBase64 }) {
            this.selectedImageBase64 = imageBase64;
            this.selectedImageFileName = fileName;
        },
        done() {
            if (this.stepOne) {
                this.upload();
            } else if (this.stepTwo) {
                this.dirtyImages[this.selectedImageKey] = {
                    fileName: this.selectedImageFileName,
                    imageBase64: this.selectedImageBase64
                };
                this.gotoStepOne();
            } else if (this.stepThree) {
                this.$emit("uploaded", this.result);
            }
        },
        cancel() {
            if (this.stepTwo) {
                this.gotoStepOne();
            } else {
                this.$emit("close");
            }
        },
        gotoStepOne() {
            this.step = 1;
            this.selectedImageKey = undefined;
            this.selectedImageBase64 = undefined;
            this.selectedImageFileName = undefined;
        },
        upload() {
            var payloads = [];
            let inventoryItemID = this.product.inventoryItemID;
            let inventoryItemSubID = this.product.inventoryItemSubs[0]
                .inventoryItemSubID;
            let storeWaypointID = this.$route.params.store;
            let airportIdent = this.store.airportIdent;

            for (let imageKey in this.dirtyImages) {
                payloads.push({
                    inventoryItemID,
                    inventoryItemSubID,
                    storeWaypointID,
                    airportIdent,
                    imageKey,
                    imageTypeID: this.imageInfo[imageKey].typeID,
                    maxDimensions: this.imageInfo[imageKey].maxDimensions,
                    imageBase64: this.dirtyImages[imageKey].imageBase64,
                    imageName: generateImageFileName(
                        inventoryItemID,
                        imageKey,
                        this.dirtyImages[imageKey].fileName
                    )
                });
            }
            this.uploadImageV2(payloads)
                .then(() => {
                    this.result = payloads.map(el => {
                        return {
                            key: el.imageKey,
                            imageBase64: el.imageBase64,
                            fileName: `/menu/${storeWaypointID}/${el.imageName}`
                        };
                    });

                    this.step = 3;
                })
                .catch(error => {
                    this.toast("Error saving images.", "error");
                });
        },
        ...mapActions("ImageUploadStore", ["uploadImageV2"])
    }
};
</script>

<style lang="scss" scoped>
.box {
  background-color: white;
  box-shadow: inset 0 1px 0 0 #e4e4e4, inset 0 -1px 0 0 #e4e4e4,
    inset 1px 0 0 0 #e4e4e4, inset -1px 0 0 0 #e4e4e4;
  // height: 19vh;
}
.image-card {
  padding: 2px;
  background-color: transparent !important;
}
.image-label {
  color: #555555 !important;
  opacity: 0.5;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

