var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 mb-3" },
    [
      _c("v-row", { staticClass: "ml-0 mt-3 mb-3" }, [
        _c("div", { staticClass: "image-label" }, [_vm._v("Images:")]),
        _vm.userHasEditPermission("IMAGES") && !_vm.editing
          ? _c(
              "div",
              {
                staticClass: "add-new",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.addImageModal = true
                  },
                },
              },
              [
                _c("div", { staticClass: "add-new__img" }),
                _c("div", { staticClass: "add-new__label" }, [
                  _vm._v("Add New Image"),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      !_vm.editing && _vm.imageArray.length
        ? _c(
            "v-row",
            { staticClass: "image-row" },
            _vm._l(_vm.imageArray, function (image) {
              return _c(
                "v-card",
                { key: image.imageType, staticClass: "image-card elevation-0" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pl-0 pr-0 justify-center" },
                    [
                      _vm.userHasEditPermission("IMAGES")
                        ? _c("div", {
                            staticClass: "image--edit",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onEditImage(image)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.userHasEditPermission("IMAGES") &&
                      image.imageType != `img_asset_384x216`
                        ? _c("div", {
                            staticClass: "image--delete",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onDeleteImage(image)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c("v-img", {
                    attrs: {
                      src: image.imageUrl,
                      "lazy-src": image.imageUrl,
                      "aspect-ratio": image.imageAspectRatio,
                      height: _vm.imageHeight,
                      width: "215",
                      position: "center",
                      contain: "",
                    },
                    on: { error: _vm.onImageError },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "placeholder",
                          fn: function () {
                            return [
                              _c(
                                "v-row",
                                {
                                  staticClass: "fill-height ma-0",
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "#93BD20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("v-card-text", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(image.display)),
                  ]),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.addImageModal
        ? _c("AddImageModal", {
            attrs: { product: _vm.product, images: _vm.imageArray },
            on: { close: _vm.onAddClose, uploaded: _vm.onUpload },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }