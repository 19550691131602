var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tags",
      staticStyle: { width: "100%" },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        { staticClass: "tags__tags" },
        [
          _c(
            "transition-group",
            { attrs: { name: "tag" } },
            [
              _vm._l(_vm.value, function (tag, i) {
                return _c(
                  "span",
                  { key: tag.tagDescription, staticClass: "tags__tag" },
                  [
                    _vm._v(" " + _vm._s(tag.tagDescription) + " "),
                    _c("span", {
                      staticClass: "tags__tag-remove",
                      attrs: { "data-index": i },
                      on: { click: _vm.removeTag },
                    }),
                  ]
                )
              }),
              !_vm.addingTag
                ? _c("div", {
                    key: "add_tag",
                    staticClass: "tags__add",
                    class: { adding: _vm.addingTag },
                    on: {
                      click: function ($event) {
                        _vm.addingTag = true
                      },
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm.addingTag
        ? _c("select-input", {
            staticClass: "block-xs--xs-bottom category-temp",
            attrs: {
              options: _vm.tagOptions,
              label: "New Tag",
              maxWidth: "30%",
            },
            on: { input: _vm.onTagSelectionChange },
            model: {
              value: _vm.addedTag,
              callback: function ($$v) {
                _vm.addedTag = $$v
              },
              expression: "addedTag",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }