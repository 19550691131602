<template>
    <div class="col-6">
        <div class="row no-gutters">
            <div
                v-for="platform in sortedPlatforms"
                :key="platform.platformTypeID"
                class="platform-toggle col-4"
            >
                <Toggle
                    v-model="toggles[platform.platformTypeID].bPlatformEnabled"
                    :label="platform.display" 
                    slider="true"
                />

            </div>
        </div>
    </div>
</template>

<script>
import Toggle from 'components/cleverly/Toggle.vue'
import { mapActions, mapGetters } from 'vuex'
import clone from 'clone'
import _ from 'lodash'
import { platformMap } from '../helpers/products'

export default {
    name: 'ProductPlatformEdit',
    components: {
        Toggle
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        productId: {
            type: String,
            default: () => ""
        }
    },
    data() {
        return {
            toggles: {},
            previousProductId: "",
            initialValues: {},
        }
    },
    computed: {
        sortedPlatforms() {
            const filteredPlatforms = this.value.filter(platform => {
                if (platform.platformTypeID == 5) return false
                return (platformMap[platform.platformTypeID])
            })
                .map(platform => {
                    return {
                        ...platform,
                        display: platformMap[platform.platformTypeID].display,
                        order: platformMap[platform.platformTypeID].order
                    }
                })
            return  _.sortBy(filteredPlatforms, el => el.order)
        }
    },
    created() {
        if (this.previousProductId === "") {
            this.previousProductId = this.productId
            this.toggles = undefined
            this.toggles = _.keyBy(this.value, el => el.platformTypeID)
            this.initialValues = clone(this.toggles)
        }
    },
    beforeUpdate() {
        if (this.productId != this.previousProductId) {
            this.previousProductId = this.productId
            this.toggles = undefined
            this.toggles = _.keyBy(this.value, el => el.platformTypeID)
            this.initialValues = clone(this.toggles)
        }
    },

    watch: {
        toggles: {
            deep: true,
            handler(after, before) {
                if (before[0] === undefined || after === undefined) return //initial set
                const newValue = _.values(after).map(el => {
                    return {
                        ...el,
                        dirty: after[el.platformTypeID].bPlatformEnabled != this.initialValues[el.platformTypeID].bPlatformEnabled
                    }
                })
                this.$emit('input', newValue)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .platform-toggle {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
</style>

