// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/plus.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../img/delete.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../img/edit_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".image-label[data-v-b88f8ee8] {\n  flex-grow: 0 !important;\n  height: 16px;\n  width: 47px;\n  color: #555555;\n  font-family: Roboto;\n  font-size: 14px;\n  font-weight: bold;\n  line-height: 20px;\n}\n.image-card[data-v-b88f8ee8] {\n  margin-right: 10px;\n  background-color: transparent !important;\n}\n.image-row[data-v-b88f8ee8] {\n  overflow: auto;\n  padding-left: 15px;\n  padding-right: 5px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  flex-grow: 0 !important;\n}\n.add-new[data-v-b88f8ee8] {\n  vertical-align: middle;\n  display: flex;\n  cursor: pointer;\n}\n.add-new__img[data-v-b88f8ee8] {\n  margin-left: 64px;\n  width: 18px;\n  height: 18px;\n  background-position: right center;\n  background-color: #93BD20;\n  -webkit-mask-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n          mask-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  -webkit-mask-repeat: no-repeat;\n          mask-repeat: no-repeat;\n  -webkit-mask-size: 15px 19px;\n          mask-size: 15px 19px;\n}\n.add-new__label[data-v-b88f8ee8] {\n  height: 21px;\n  width: 115px;\n  padding-left: 3px;\n  color: #72980a;\n  font-family: Roboto;\n  font-size: 14px;\n  font-weight: bold;\n  line-height: 21px;\n}\n.image--delete[data-v-b88f8ee8] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: 20px;\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n  margin-top: -2px;\n}\n.image--edit[data-v-b88f8ee8] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-repeat: no-repeat;\n  background-size: 14px;\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n  padding: 5px;\n}", ""]);
// Exports
module.exports = exports;
