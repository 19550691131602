<template>
  <div class="row no-gutters">
    <div class="variation-builder__struct col-12">
      <div
        v-if="errorMessage"
        class="variation-builder__error block-xs--md-bottom row no-gutters align-items-center"
      >
        <div class="col-auto">
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </div>

    <variation-table
      v-model="variationItems"
      :struct="fullStruct"
      :value="variationItems"
      :disabled="integrated"
      :forceEditable="['inventoryItemSubName']"
      :integrated="integrated"
      :bespoke="bespoke"
      @add="handleAdd"
      @remove="removeVariation"
      @subNameChanged="subNameChanged"
      @reorder="reorder"
    />

    <AddVariationModal
      v-if="showItemModal"
      @add="addPosVariation"
      @close="() => this.showItemModal = false"
    />
  </div>
</template>

<script>
import Vue from "vue";
import FormInput from "./cleverly/FormInput";
import VariationTable from "./VariationTable";
import clone from "clone";
import { mapGetters } from "vuex";
import AddVariationModal from "components/AddVariationModal";

export default {
    name: "VariationBuilder",

    // Components

    components: {
        FormInput,
        VariationTable,
        AddVariationModal
    },

    // Props

    props: {
        value: {
            type: Array,
            required: true
        },

        posItems: {
            type: Array,
            default: () => []
        },

        integrated: {
            type: Boolean,
            default: false
        },

        productId: {
            type: String
        },
        bespoke: {
            type: Boolean,
            default: false
        }
    },

    // Data

    data() {
        return {
            struct: [],
            errorMessage: "",
            showItemModal: false
        };
    },

    // Computed

    computed: {
        fullStruct() {
            if (!this.integrated) {
                const fields = [
                    { name: "inventoryItemSubName", display: "Name" },
                    { name: "cost", display: "Price" }
                ];

                fields.push({ name: "sku", display: "ID" });

                return fields;
            } else {
                const fields = [
                    { name: "inventoryItemSubName", display: "Display Name" },
                    { name: "posProductName", display: "POS Item Name" },
                    { name: "cost", display: "Price" }
                ];

                var idField = this.integrated ? "pos_sizeID" : "sku";
                if (this.pos == "omnivore" || this.pois == "infogensis") {
                    idField = "pos_productID";
                }
                fields.push({ name: idField, display: "ID" });

                return fields;
            }
        },

        baseVariation() {
            return this.value.length && this.value[0].inventoryItemSubName === "MAIN"
                ? this.value[0]
                : false;
        },

        variationItems() {
            let variations = clone(this.value)//.filter(el => el.inventoryItemSubName != "MAIN");

            if (!this.integrated) return variations;

            return variations.map(variation => {
                let posProduct = this.posProducts.find(
                    el => el.pos_productID == variation.pos_productID
                );
                return {
                    ...variation,
                    posProductName:
            posProduct == undefined ? "" : posProduct.pos_productName
                };
            })
        },
        ...mapGetters(["posProducts", "pos"])
    },

    // Methods

    methods: {
        handleAdd() {
            if (this.integrated) {
                this.showItemModal = true;
            } else {
                this.addVariation();
            }
        },

        addVariation() {
            this.value.push();
            this.$emit(
                "change",
                this.value.concat({
                    tmpID: this.value.length + 1,
                    sku: "",
                    cost: "",
                    inventoryItemSubName: ""
                })
            );
        },

        addPosVariation(variations) {
            var base = clone(this.value);
            if (
                base != undefined &&
        base.length == 1 &&
        base[0].inventoryItemSubName == "MAIN"
            ) {
                let posProductID =
          base[0].pos_productID == undefined
              ? this.productId
              : base[0].pos_productID;
                let posProduct = this.posProducts.find(
                    el => el.pos_productID == posProductID
                );
                base = [
                    {
                        ...base[0],
                        pos_productID: posProductID,
                        inventoryItemSubName: posProduct
                            ? posProduct.pos_productName
                            : "change me"
                    }
                ];
            }

            this.$emit(
                "change",
                base.concat(
                    variations.map(el => {
                        var ret = {
                            ...el,
                            inventoryItemSubName: el.name,
                            pos_sizeID: el.pos_sizeID
                        };
                        delete ret.name
                        return ret
                    })
                )
            );

            this.showItemModal = false;
        },

        removeVariation(variation) {
            var base = clone(this.value);
            let index = base.findIndex(el => {
                if (el.tmpID != undefined) {
                    return sub.el == variation.tmpID
                } else if (variation.inventoryItemSubID != undefined) {
                    return el.inventoryItemSubID == variation.inventoryItemSubID;
                } else if (variation.pos_productID != undefined) {
                    return el.pos_productID == variation.pos_productID;
                } else {
                    return el.inventoryItemSubName == variation.inventoryItemSubName;
                }
            });
            if (index == -1) {
                return;
            }

            base.splice(index, 1);
            this.$emit("change", base);
            this.$emit("remove", variation);
        },
        subNameChanged(sub) {
            let index = this.value.findIndex(variation => {
                if (sub.tmpID != undefined) {
                    return sub.tmpID == variation.tmpID
                } else if (sub.inventoryItemSubID != undefined) {
                    return sub.inventoryItemSubID == variation.inventoryItemSubID;
                } else if (sub.pos_productID != undefined) {
                    return sub.pos_productID == variation.pos_productID;
                } else if (sub.sku != undefined) {
                    return sub.sku == variation.sku;
                } else {
                    return sub.inventoryItemSubName == variation.inventoryItemSubName;
                }
            });
            if (index == -1) return;
            var base = clone(this.value);
            base.splice(index, 1, sub);
            this.$emit("change", base);
        },
        reorder(items) {
            this.$emit("change", items)
        }
    },
    mounted() {
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.variation-builder {
  &__button {
    cursor: pointer;
  }

  &__error {
    font-size: fz(xs);
    color: $error_red;
  }
}

.pos-product-picker {
  padding-top: 30px;
}
</style>
