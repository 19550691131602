var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-box" }, [
    _vm.label
      ? _c("label", { class: _vm.hasValue, attrs: { for: _vm.id } }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _vm._e(),
    _c("div", { staticClass: "text-box__container" }, [
      _c("textarea", {
        class: _vm.hasValue,
        attrs: {
          id: _vm.id,
          maxlength: _vm.maxlength,
          cols: _vm.cols,
          rows: _vm.rows,
          spellcheck: _vm.spellcheck,
        },
        domProps: { value: _vm.value },
        on: {
          input: _vm.onInput,
          focus: function ($event) {
            _vm.hasValue = ""
          },
          blur: function ($event) {
            _vm.hasValue = _vm.value ? "" : " empty"
          },
        },
      }),
      _vm.maxlength || _vm.showCount
        ? _c("div", { staticClass: "text-box__count block-xs--xxs" }, [
            _vm._v(" " + _vm._s(_vm.count)),
            _vm.maxlength
              ? _c("span", [_vm._v("/" + _vm._s(_vm.maxlength))])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }