var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "EditingModal",
    { attrs: { size: "md" } },
    [
      _c("editing-modal-header", {
        attrs: {
          manualTitle: "Add Variations",
          cancelAction: () => {
            this.$emit("close")
          },
          saveAction: _vm.addVariation,
        },
      }),
      _c("DatabasePicker", {
        staticClass: "search-list col-12",
        attrs: {
          activeItems: _vm.activeItems,
          items: _vm.items,
          struct: _vm.struct,
          searchProps: _vm.searchProps,
        },
        on: { selected: _vm.handleItemClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }