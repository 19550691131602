<template>
    <div class="d-flex justify-content-end">
        <action-button 
            :action="cancel" 
            class="col-auto" 
            type="cancel">Cancel</action-button>
        <action-button 
            :action="proceed" 
            class="col-auto" 
            type="proceed">Save</action-button>
    </div>
</template>

<script>
import actionButton from './action_button.vue'

export default {
    name: 'ActionButtonSet',
    components: {
        actionButton,
    },
    props: {
        cancel: {
            type: Function,
            required: true,
        },
        proceed: {
            type: Function,
            required: true,
        },
    },
}
</script>
