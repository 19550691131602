<template>
    <div>
        <div class="editing-modal__header height-sm--8 d-flex justify-content-between align-items-center">
            <template v-if="manualTitle">
                <h2 class="editing-modal__title">
                    {{ manualTitle }}
                </h2>
            </template>

            <template v-else>
                <h2 class="editing-modal__title">
                    Editing {{ items.length }} item{{ (items.length > 1) ? 's' : '' }}
                </h2>
            </template>

            <div class="editing-modal__actions">
                <action-button-set
                    :cancel="cancelAction"
                    :proceed="saveAction" />
            </div>

        </div>

        <div class="editing-modal__header-edit-list">
            <ul>
                <li v-for="item in items">
                    <span>{{ item.id }}</span>&nbsp;&nbsp;
                    <span>{{ item[displayProp] }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import actionButtonSet from './action_button_set'

export default {
    name: 'EditingModalHeader',
    components: {
        actionButtonSet,
    },
    props: {
        manualTitle: {
            type: String,
        },
        items: {
            type: Array,
            default: () => [],
        },
        displayProp: {
            type: String,
            default: () => 'title',
        },
        cancelAction: {
            type: Function,
            required: true,
        },
        saveAction: {
            type: Function,
            required: true,
        },
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.editing-modal__header {
    &-edit-list {
        margin-bottom: spacing(sm);
        margin-left: spacing(sm);
        padding-right: spacing(sm);
    }
}

.editing-modal__title {
    margin-left: spacing(sm);
}
</style>
