<template>
    <div class="add-mod-set row no-gutters">
        <div class="add-mod-set col-12">
            <template v-if="modSets.length">
                <div class="row no-gutters">
                    <line-item
                        v-for="modSet in modSets"
                        :key="Math.random()"
                        class="add-mod-set__item col-12"
                        @click.native="$emit('save', modSet)"
                    >
                        {{ modSet.title }}
                    </line-item>
                </div>
            </template>

            <template v-else>
                <div class="empty-message">There are no mod sets for this store. Please add a set and try again.</div>
            </template>
        </div>
    </div>
</template>

<script>
import lineItem from 'components/line_item.vue'

export default {
    name: 'AddModSet',
    components: {
        lineItem,
    },
    props: {
        modSets: Array,
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/mixins';

.add-mod-set {
    &__item {
        cursor: pointer;
        padding: spacing(sm);

        &:hover {
            background-color: rgba($blue_light_1, 0.2);
            color: $blue_mid_1;
        }
    }
}

.empty-message {
    padding: 30px 20px;
}

</style>
