<template>
  <v-container class="pa-0 mb-3">
    <v-row class="ml-0 mt-3 mb-3" >
      <div class="image-label">Images:</div>
      <!-- <v-flex md-11 class="add-new"> -->
      <div class="add-new" v-if="userHasEditPermission('IMAGES') && !editing" @click.stop="addImageModal = true">
        <div class="add-new__img" />
        <div class="add-new__label">Add New Image</div>
      </div>
      <!-- </v-flex> -->
    </v-row>
    <v-row class="image-row"  v-if="!editing && imageArray.length">
      <!-- <v-flex > -->
      <!-- <ImageCard
        :key="image.imageType"
        :image="image"
        :height="imageHeight"
        maxWidth=250
        :width="calcImageWidth(imageHeight, image.imageAspectRatio)"
      />-->
      <v-card class="image-card elevation-0" v-for="image in imageArray" :key="image.imageType">
        <v-card-title class="pl-0 pr-0 justify-center">
          <div v-if="userHasEditPermission('IMAGES')" class="image--edit" @click.stop="onEditImage(image)"></div>
          <div v-if="userHasEditPermission('IMAGES') && image.imageType != `img_asset_384x216`" class="image--delete" @click.stop="onDeleteImage(image)"></div>
        </v-card-title>
        <v-img
          :src="image.imageUrl"
          :lazy-src="image.imageUrl"
          :aspect-ratio="image.imageAspectRatio"
          :height="imageHeight"
          @error="onImageError"
          width="215"
          position="center"
          contain
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="#93BD20"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-card-text class="text-center">{{ image.display }}</v-card-text>
      </v-card>
      <!-- </v-flex> -->
    </v-row>
    <AddImageModal
      v-if="addImageModal"
      :product="product"
      :images="imageArray"
      @close="onAddClose"
      @uploaded="onUpload"
    />
  </v-container>
</template>

<script>
import { transformImageV2ToArray } from "helpers/images.js";
import AddImageModal from "./add_image_modal";
import clone from "clone";
import {
    fullUrlForImageValue,
    aspectRatioForImageKey,
    imagesPageDisplayStringForImageKey,
    dimensionsForImageKey,
    missingImageForImageKey,
    imageUploadInfo,
    generateImageFileName,
    displayStringForImageKey
} from "helpers/images.js";

import AccessMixin from "mixins/access_helper.js";

import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            editing: false,
            imageHeight: 130,
            addImageModal: false,
            imageArray: []
        };
    },
    mixins: [AccessMixin],
    components: {
        AddImageModal
    },
    props: {
        images: {
            type: Object,
            default: () => undefined
        },
        product: {
            type: Object
        }
    },
    computed: {
    // imageArray() {
    //   if (this.images == undefined) return [];
    //   return ;
    // }
    },
    mounted() {
        this.init();
    },
    methods: {
        onAddClose() {
            this.addImageModal = false;
        },
        calcImageWidth(height, aspectRatio) {
            return height * aspectRatio;
        },
        onEditImage(image) {
            this.addImageModal = true;
        },
        onDeleteImage(image) {
            let storeWaypointID = this.$route.params.store;
            let airportIdent = this.store.airportIdent;
            let imageTypeID = imageUploadInfo()[image.imageType].typeID;

            let images = [
                {
                    inventoryItemID: this.product.inventoryItemID,
                    inventoryItemSubID: this.product.inventoryItemSubs[0]
                        .inventoryItemSubID,
                    imageName: ""
                }
            ];

            let payload = {
                storeWaypointID,
                airportIdent,
                imageTypeID,
                images
            };

            return this.setImageV2(payload).then(() => {
                let index = this.imageArray.findIndex(
                    el => el.imageType == image.imageType
                );
                if (index == -1) return;
                this.imageArray.splice(index, 1);
            });
        },
        onUpload(payload) {
            var newArray = clone(this.imageArray);
            for (let uploaded of payload) {
                let index = newArray.findIndex(el => el.imageType == uploaded.key);

                if (uploaded.key == "img_asset_384x216") {
                    let fileName = uploaded.fileName;
                    this.$emit("inventoryItemImageNameChanged", fileName);
                }
                if (index == -1) {
                    newArray.push({
                        imageType: uploaded.key,
                        imageUrl: uploaded.imageBase64,
                        imageAspectRatio: aspectRatioForImageKey(uploaded.key),
                        display: displayStringForImageKey(uploaded.key)
                    });
                } else {
                    newArray.splice(index, 1, {
                        imageType: uploaded.key,
                        imageUrl: uploaded.imageBase64,
                        imageAspectRatio: aspectRatioForImageKey(uploaded.key),
                        display: displayStringForImageKey(uploaded.key)
                    });
                }
            }
            this.imageArray = newArray;
            this.addImageModal = false;
        },
        init() {
            this.imageArray = [];
            this.$nextTick(() => {
                this.imageArray = transformImageV2ToArray(this.images);
            });
        },
        onImageError() {
            console.log("image error");
        },
        ...mapActions("ImageUploadStore", ["setImageV2"])
    },
    computed: {
        ...mapGetters(["store"])
    },
    watch: {
        product: function() {
            this.init();
        }
    },
    name: "StoreProductEditImageRow"
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.image-label {
  flex-grow: 0 !important;
  height: 16px;
  width: 47px;
  color: #555555;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.image-card {
  margin-right: 10px;
  background-color: transparent !important;
}

.image-row {
  overflow: auto;
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 0 !important;
}
.add-new {
  vertical-align: middle;
  display: flex;
  cursor: pointer;

  &__img {
    margin-left: 64px;
    width: spacing(sm);
    height: spacing(sm);
    background-position: right center;
    background-color: $brand_color;
    @include mask-image("", "plus.svg", "../../img/");
    mask-size: 15px 19px;
  }

  &__label {
    height: 21px;
    width: 115px;
    padding-left: 3px;
    color: #72980a;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
  }
}

.image {
  &--delete {
    @include background-image("", "delete.svg", "../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../../img/");
    background-size: 14px;
    cursor: pointer;

    width: 20px;
    height: 20px;
    padding: 5px;
  }
}
</style>

