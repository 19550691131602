<style lang="scss">
    @import '~scss/variables';
    @import '~scss/mixins';
    // $transition_short: 1s;
    .tags {
        display: inline-block;
        vertical-align: middle;
        &__tags {
            display: inline-block;
        }
        &__tag {
            display: inline-block;
            background-color: $chip;
            border-radius: 25px;
            border: 1px solid $chip_dark;
            padding: 8px 10px 8px 15px;
            margin-right: 10px;
            margin-bottom: spacing(xs);
            &-remove {
                position: relative;
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-left: 5px;
                cursor: pointer;
                vertical-align: middle;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 12px;
                    height: 2px;
                    background-color: $chip_dark;
                }
                &:before {
                    transform: translate(-50%, -50%) rotate(45deg)
                }
                &:after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
        &__add {
            position: relative;
            background-color: $chip;
            display: inline-block;
            width: 34px;
            height: 34px;
            border: 1px solid $chip_dark;
            border-radius: 100%;
            margin-bottom: spacing(xs);
            cursor: pointer;
            vertical-align: bottom;
            overflow: hidden;
            transition: all $transition_short;

            input {
                background-color: $chip;
                box-shadow: none !important;
                width: 100%;
                border: none;
            }
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 12px;
                height: 2px;
                background-color: $chip_dark;
            }
            &:before {
                transform: translate(-50%, -50%)
            }
            &:after {
                transform: translate(-50%, -50%) rotate(90deg);
            }
            &.adding {
                width: 214px;
                padding: 0 10px;
                border-radius: 25px;
                &:before,
                &:after {
                    display: none;
                }
            }
        }
        .form-enter-active,
        .form-leave-active {
            transition: all $transition_short;
        }
        .form-enter,
        .form-leave-active {
            opacity: 0;
            transform: scale(0);
            // position: absolute;
        }
        .form-leave-active {
        }

        .tag-enter-active,
        .tag-leave-active,
        .tag-move {
            transition: all $transition_short;
        }
        .tag-enter,
        .tag-leave-active {
            opacity: 0;
            transform: scale(0);
        }
        .tag-leave-active {
            position: absolute;
        }
    }
</style>
<template>
    <div :id="id" class="tags" style="width: 100%;">
        <div class="tags__tags">
            <transition-group name="tag">
                 <span class="tags__tag" v-for="(tag, i) in value" :key="tag.tagDescription">
                     {{ tag.tagDescription }}
                     <span class="tags__tag-remove" @click="removeTag" :data-index="i"></span>
                 </span>
                 <div v-if="!addingTag" class="tags__add" :class="{adding: addingTag}" key="add_tag" @click="addingTag = true">
                 </div>
            </transition-group>
        </div>
                <select-input
                    v-if="addingTag"
                    v-model="addedTag"
                    :options="tagOptions"
                    label="New Tag"
                    maxWidth="30%"
                    class="block-xs--xs-bottom category-temp"
                    @input="onTagSelectionChange"
                    />
    </div>
</template>
<script>
import Vue from 'vue'
import FormInput from './FormInput'
import SelectInput from './SelectInput'

export default {
    name: 'Tags',
    data: () => {
        return {
            id: `tags-${Math.random().toString(36).substring(7)}`,
            addingTag: false,
            addedTag: '',
            newTag: {
                tagDescription: '',
                tagOrder: 1,
            },
        }
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        tags: {
            type: Array,
            required: true,
        }
    },
    computed: {
        tagOptions() {
            const existing = this.value.map(el => el.tagDescription)
            return this.tags.filter(el => !existing.includes(el)).map(el => { 
                return {
                    value: el,
                    text: el
                }})
        }
    },
    methods: {
        onTagSelectionChange(e) {
            const newTag = {
                tagDescription: this.addedTag,
                tagOrder: this.value.length + 1
            }

            const newVal = [...this.value, newTag]

            this.$emit('add', newTag)
            this.$emit('input', newVal)
            this.resetAddTag()

        },
        removeTag(e) {
            let index = Number(e.currentTarget.dataset.index)
            let newVal = [...this.value.filter(((tag, i) => {
                if (i === index) {
                    this.$emit('remove', tag)
                    return false
                }
                return true
            }))]

            this.$emit('input', newVal)
        },
        addTag() {
            const value = this.newTag

            if (value.tagDescription && value.tagDescription.length) {
                const newVal = [...this.value, value]

                this.$emit('add', value)
                this.$emit('input', newVal)
                this.resetAddTag()
            }
        },
        resetAddTag() {
            this.newTag = {
                tagDescription: '',
                tagOrder: 1,
            }
            this.addingTag = false
            this.addedTag = ''
        }
    },
    watch: {
        addingTag() {
            Vue.nextTick(() => {
                if (this.$refs.input) {
                    this
                        .$refs
                        .input
                        .$el
                        .querySelector('input')
                        .focus()
                }
            })
        },
    },
    components: {
        FormInput,
        SelectInput
    },
}
</script>
