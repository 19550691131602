var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row no-gutters" },
    [
      _c("div", { staticClass: "variation-builder__struct col-12" }, [
        _vm.errorMessage
          ? _c(
              "div",
              {
                staticClass:
                  "variation-builder__error block-xs--md-bottom row no-gutters align-items-center",
              },
              [
                _c("div", { staticClass: "col-auto" }, [
                  _c("p", [_vm._v(_vm._s(_vm.errorMessage))]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _c("variation-table", {
        attrs: {
          struct: _vm.fullStruct,
          value: _vm.variationItems,
          disabled: _vm.integrated,
          forceEditable: ["inventoryItemSubName"],
          integrated: _vm.integrated,
          bespoke: _vm.bespoke,
        },
        on: {
          add: _vm.handleAdd,
          remove: _vm.removeVariation,
          subNameChanged: _vm.subNameChanged,
          reorder: _vm.reorder,
        },
        model: {
          value: _vm.variationItems,
          callback: function ($$v) {
            _vm.variationItems = $$v
          },
          expression: "variationItems",
        },
      }),
      _vm.showItemModal
        ? _c("AddVariationModal", {
            on: {
              add: _vm.addPosVariation,
              close: () => (this.showItemModal = false),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }