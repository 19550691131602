var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add-mod-set row no-gutters" }, [
    _c(
      "div",
      { staticClass: "add-mod-set col-12" },
      [
        _vm.modSets.length
          ? [
              _c(
                "div",
                { staticClass: "row no-gutters" },
                _vm._l(_vm.modSets, function (modSet) {
                  return _c(
                    "line-item",
                    {
                      key: Math.random(),
                      staticClass: "add-mod-set__item col-12",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("save", modSet)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(modSet.title) + " ")]
                  )
                }),
                1
              ),
            ]
          : [
              _c("div", { staticClass: "empty-message" }, [
                _vm._v(
                  "There are no mod sets for this store. Please add a set and try again."
                ),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }