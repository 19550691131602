
<template>
  <EditingModal size="md">
    <editing-modal-header
      manualTitle="Add Variations"
      :cancelAction="() => { this.$emit('close') }"
      :saveAction="addVariation"
    />
    <DatabasePicker
      :activeItems="activeItems"
      :items="items"
      :struct="struct"
      :searchProps="searchProps"
      class="search-list col-12"
      @selected="handleItemClick"
    />
  </EditingModal>
</template>

<script>
import clone from "clone";
import DatabasePicker from "components/NewDatabasePicker";
import Toggle from "components/cleverly/Toggle";
import { toggleItem } from "helpers";
import { mapGetters } from "vuex";
import { removeDups } from "helpers";
import EditingModalHeader from "components/editing_modal_header";
import EditingModal from "components/editing_modal";
import { OMNI_posSizeIDIfExists } from "helpers/products";

export default {
    name: "AddPosProduct",

    // Components

    components: {
        DatabasePicker,
        Toggle,
        EditingModalHeader,
        EditingModal
    },

    // Data

    data() {
        return {
            searchProps: ["pos_productID", "pos_productName", "pos_productCategory"],
            activeItems: [],
            struct: {
                title: {
                    value: item => item.pos_productName
                },
                sku: {
                    display: "Item ID",
                    value: item => item.pos_productID
                },
                price: {
                    display: "Price",
                    value: this.priceItem
                },
                path: {
                    display: "Path",
                    value: item => item.pos_productCategory
                }
            },
            baseSizeStruct: {
                cost: "",
                inventoryItemID: this.productId,
                pos_sizeID: "",
                sku: "",
                selected: false,
                inventoryItemSubName: "",
                inventoryItemSubID: "",
                inventoryOrder: "1",
                pos_productID: ""
            },
            selectMultipleSizes: false
        };
    },

    // Methods

    methods: {
        handleItemClick(item) {
            this.activeItems = toggleItem(this.activeItems, item.pos_productID);
        },

        addVariation() {
            if (this.activeItems.length == 0) {
                this.$emit("close");
                return;
            }

            const payload = this.activeItems.map(el => {
                const item = this.posProducts.find(
                    product => product.pos_productID === el
                );
                if (!item) {
                    return false;
                }
                const sizes = item.pos_productSizes;
                const cost = sizes.length ? sizes[0].cost : 0;
                const sizeID = OMNI_posSizeIDIfExists(item)
                return {
                    name: item.pos_productName,
                    pos_productID: item.pos_productID,
                    category: item.pos_productCategory,
                    cost,
                    pos_sizeID: sizeID
                };
            });

            this.$emit("add", payload);
        },
        priceItem(item) {
            const sizes = item.pos_productSizes;

            if (!sizes.length) {
                return "N/A";
            }
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
            });
            return `${this.store.currencySymbol}${Number(sizes[0].cost).toFixed(2)}`;

            return "$" + sizes[0].cost;
        }
    },
    computed: {
        actions() {
            if (this.activeItems.length == 0) return [];
            return [
                {
                    type: "green",
                    display: "Add",
                    run: () => {
                        this.$emit("close");
                    }
                }
            ];
        },
        items() {
            return removeDups(
                this.posProducts.map(el => {
                    return Object.assign(el, { id: el.pos_productID });
                }),
                "id"
            );
        },
        ...mapGetters(["posProducts", "store"])
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.heading {
  padding-left: 18px;
  margin-bottom: 10px;
  font-weight: 700;
}

.list {
  max-height: 500px;
}

.search-list {
  height: 400px !important;
}

.alert-dialog {
  &__body {
    padding: 0px !important;
  }
}

.toggle {
  margin-left: 15px;
}
</style>
