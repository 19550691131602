var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-6" }, [
    _c(
      "div",
      { staticClass: "row no-gutters" },
      _vm._l(_vm.sortedPlatforms, function (platform) {
        return _c(
          "div",
          {
            key: platform.platformTypeID,
            staticClass: "platform-toggle col-4",
          },
          [
            _c("Toggle", {
              attrs: { label: platform.display, slider: "true" },
              model: {
                value: _vm.toggles[platform.platformTypeID].bPlatformEnabled,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.toggles[platform.platformTypeID],
                    "bPlatformEnabled",
                    $$v
                  )
                },
                expression: "toggles[platform.platformTypeID].bPlatformEnabled",
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }