<template>
  <form class="product-form">
    <line-item
      v-if="
        existsOnProduct('inventoryItemName') ||
        (existsOnProduct('inventoryItemSubs') &&
          value.inventoryItemSubs.length) ||
        existsOnProduct('inventoryItemTitles') ||
        existsOnProduct('inventoryItemDescription') ||
        existsOnProduct('healthInformation') ||
        existsOnProduct('additionalTurnaround') ||
        existsOnProduct('bAlcohol')
      "
      class="row no-gutters"
    >
      <div class="col-12">
        <form-input
          v-if="existsOnProduct('inventoryItemName')"
          v-model="value.inventoryItemName"
          class="product-form-control large block-xs--xs"
          label="Name"
          spellcheck="true"
        />

        <div class="d-flex">
          <template v-if="existsOnProduct('inventoryItemSubs')">
            <template v-if="integrated || value.inventoryItemSubs.length > 1">
              <form-input
                v-model="value.costDisplay"
                :disabled="true"
                class="block-xs--xs-bottom adjacent-form"
                label="Price"
              />
            </template>

            <template
              v-else-if="
                existsOnProduct('inventoryItemSubs') &&
                value.inventoryItemSubs.length === 1
              "
            >
              <form-input
                v-if="value.inventoryItemSubs.length === 1"
                v-model="value.inventoryItemSubs[0].cost"
                class="block-xs--xs-bottom adjacent-form"
                label="Price"
              />
            </template>
          </template>
          <template v-if="integrated">
            <form-input
              v-if="
                existsOnProduct('pos_productID') &&
                existsOnProduct('inventoryItemSubs') &&
                value.inventoryItemSubs.length === 1
              "
              v-model="value.pos_productID"
              class="block-xs--xs-bottom"
              label="Item ID"
              disabled
            />
            <form-input
              v-if="
                existsOnProduct('inventoryItemSubs') &&
                value.inventoryItemSubs.length > 1
              "
              value="Multi"
              class="block-xs--xs-bottom"
              label="Item ID"
              disabled
            />
          </template>

          <template v-else>
            <form-input
              v-if="
                existsOnProduct('inventoryItemSubs') &&
                value.inventoryItemSubs.length
              "
              v-model="value.inventoryItemID"
              class="block-xs--xs-bottom"
              label="Item ID"
            />
          </template>
        </div>
        <template v-if="existsOnProduct('inventoryItemSubs')">
          <template v-if="value.inventoryItemSubs.length === 1">
            <form-input
              v-if="value.inventoryItemSubs.length > 0"
              v-model="value.inventoryItemSubs[0].sku"
              class="block-xs--xs-bottom"
              label="UPC Code"
            />
          </template>

          <template v-else>
            <div class="block-xs--xs-bottom">
              <span class="label">UPC Code</span>

              <span class="d-inline-block"
                >Unavailable due to multiple sizes on item</span
              >
            </div>
          </template>
        </template>

        <text-box
          v-if="existsOnProduct('inventoryItemDescription')"
          v-model="value.inventoryItemDescription"
          rows="6"
          cols="65"
          class="block-xs--xs-bottom"
          label="Description"
          spellcheck="true"
        />
      </div>
    </line-item>

    <line-item
      v-if="existsOnProduct('inventoryMainOptionChoice')"
      class="row no-gutters"
    >
      <div class="col-12">
        <div class="label block-xs--xs">Modifiers</div>
        <div class="mt-0 pt-0 block-xs--xs font-weight-norma">Required Modifier Groups will always be display first to the guest, then optional modifier groups.</div>

        <div v-if="filteredModGroups.length" class="row">
          <div class="block-xs--xs-bottom col-12 col-lg-8 col-xl-6">
            <tiny-explorer
              :children="filteredModGroups"
              :permissions="modPermissions"
              :resolveChildStatus="(el) => el.required"
              :onRemoveChild="handleRemoveModGroup"
              :onRemoveGrandChild="handleRemoveMod"
              :onEditChild="handleModGroupEdit"
              :onCheckChild="handleCheckModGroup"
              :onReorder="handleReorderModGroups"
              :resolveGrandChildTitle="modGroupGrandChildTitle"
              :childCheckLabel="modGroupCheckLabel"
            />
          </div>
        </div>

        <text-button
          v-if="canEdit"
          class="block-xs--xs-bottom"
          type="add"
          @click.native="toggleModGroupModal"
        >
          Add
          <template v-if="filteredModGroups.length">another</template>
          <template v-else>a</template> Mod Group
        </text-button>

        <editing-modal v-if="modGroupModal" class="modal" size="lg">
          <editing-modal-header-simple
            title="Create or Select a Mod Group"
            @closeModal="toggleModGroupModal"
          />

          <add-mod-group
            :modGroups="groups"
            :passedModGroup="editedModGroup"
            :posMods="posMods"
            :integrated="integrated"
            :productName="value.inventoryItemName"
            @save="addModGroup"
            @removeMod="$emit('removeMod', arguments[0])"
          />
        </editing-modal>

        <edit-warning
          v-if="warningActive"
          :type="warningType"
          :effectedItems="effectedItems"
          :onCancel="handleEditCancel"
          :onContinue="handleEditContinue"
          :resolveEffectedTitle="(item) => item.inventoryItemName"
        />
      </div>
    </line-item>
    <!-- <line-item class="row no-gutters">
            <div class="col-12">
                <div class="label block-xs--xs">
                    Mod Set
                </div>

                <div
                    v-if="value.modSets.length"
                    class="row"
                >
                    <div class="block-xs--xs-bottom col-10 col-lg-6">
                        <tiny-explorer
                            :children="value.modSets"
                            :onRemoveChild="removeModSet"
                            :onSelectGrandChild="group => {modModal = true; editedModGroup = group}"
                            :permissions="['removeChild', 'viewGrandChildren', 'selectGrandChild']"
                            :sortable="false"
                        />
                    </div>
                </div>

                <text-button
                    class="block-xs--xs-bottom"
                    type="add"
                    @click.native="modSetModal = true"
                >
                    Add <template v-if="value.modSets.length">another</template><template v-else>a</template> Mod Set
                </text-button>

                <editing-modal
                    v-if="modSetModal"
                    class="modal"
                >
                    <editing-modal-header-simple
                        title="Select a Mod Set"
                        @closeModal="modSetModal = false"
                    />

                    <add-mod-set
                        :modSets="modSets"
                        @save="addModSet"
                    />
                </editing-modal>

                <editing-modal
                    v-if="modModal"
                    class="modal"
                >
                    <editing-modal-header-simple
                        title="Mods"
                        @closeModal="modModal = false; editedModGroup = false"
                    />

                    <div class="block-xs--sm-left">
                        <tiny-explorer
                            :children="editedModGroup.children"
                            :permissions="[]"
                            :sortable="false"
                        />
                    </div>
                </editing-modal>
            </div>
    </line-item>-->
    <line-item
      v-if="
        existsOnProduct('inventoryItemName') ||
        (existsOnProduct('inventoryItemSubs') &&
          value.inventoryItemSubs.length) ||
        existsOnProduct('inventoryItemTitles') ||
        existsOnProduct('inventoryItemDescription') ||
        existsOnProduct('healthInformation') ||
        existsOnProduct('additionalTurnaround') ||
        existsOnProduct('bAlcohol')
      "
      class="row no-gutters"
    >
      <div class="col-12">
        <div class="row">
          <div class="col-2">
        <select-input
          v-if="existsOnProduct('inventoryTitles')"
          v-model="category"
          :options="categoryOptions"
          class="block-xs--xs-bottom category-temp"
          label="Category"
        />
          </div>
                    <div class="col-2">

        <select-input
          v-if="existsOnProduct('inventoryTitles') && subCategoryOptions.length > 0"
          v-model="subCategory"
          :options="subCategoryOptions"
          class="block-xs--xs-bottom category-temp"
          label="Subcategory"
        />
                    </div>
        </div>
        <template v-if="existsOnProduct('bAlcohol')">
          <toggle
            v-if="existsOnProduct('bAlcohol')"
            v-model="value.bAlcohol"
            label="Alcohol"
            class="block-xs--xs-bottom"
          />
        </template>
        <template v-if="existsOnProduct('inventoryFeatureItem')">
          <toggle
            v-if="existsOnProduct('inventoryFeatureItem')"
            v-model="value.inventoryFeatureItem"
            label="Featured Item"
            class="block-xs--xs-bottom"
          />
        </template>
        <template v-if="existsOnProduct('bEmployeeItem')">
          <toggle
            v-if="existsOnProduct('bEmployeeItem')"
            v-model="value.bEmployeeItem"
            label="Employee Only"
            class="block-xs--xs-bottom"
          />
        </template>
      </div>
    </line-item>
    <line-item v-if="existsOnProduct('taxes')" class="row no-gutters">
      <div class="col-12">
        <span class="label block-xs--xs">Tax</span>

        <div class="group-inputs taxes block-lg--xs">
          <template v-for="tax in value.taxes">
            <div :key="tax.description">
              <form-input
                :value="tax.Amount | parseTaxRate"
                :label="tax.Description + ' (%)'"
                type="number"
                class="block-xs--xs-right block-xs--xs-bottom sub-label"
                @change="tax.Amount = arguments[0] / 100"
              />
            </div>
          </template>
        </div>
      </div>
    </line-item>

    <line-item
      v-if="existsOnProduct('inventoryItemSubs') && this.pos != 'tgif'"
      class="row no-gutters"
    >
      <div class="col-12">
        <span class="label block-xs--xs">Variations</span>

        <div class="d-inline-block">
          <variation-builder
            v-model="sortedSubs"
            :value="sortedSubs"
            :integrated="integrated"
            :posItems="posProducts"
            :productId="value.pos_productID"
            class="block-lg--xs"
            @remove="handleVariationDelete"
            @change="handleVariationsChange"
          />
        </div>
      </div>
    </line-item>

    <line-item
      v-if="
        existsOnProduct('startTimeLocal') &&
        existsOnProduct('endTimeLocal') &&
        availability != null
      "
      class="row no-gutters"
    >
      <div class="col-12">
        <span class="label block-xs--xs-top">Time</span>
        <div class="group-inputs block-xs--xs-bottom block-lg--xs-top">
          <time-selection
            :value="availability"
            @input="$emit('availabilityChange', $event)"
          />
        </div>
      </div>
    </line-item>
    <line-item v-if="availableTags.length != 0">
      <div>
        <div class="label block-xs--xs">Tags</div>

        <tags
          v-model="value.inventoryTags"
          :tags="availableTags"
          class="block-xs--xs-top"
        />
      </div>
    </line-item>
    <!-- v-if="existsOnProduct('inventoryItemAttributeID') || (existsOnProduct('inventoryTags') && (value.inventoryItemID !== ''))" -->
    <line-item
      v-if="existsOnProduct('inventoryItemAttributeID')"
      class="row no-gutters"
    >
      <div class="col-12">
        <div>
          <div class="label block-xs--md">Attributes</div>
          <div class="group-inputs block-xs--xs">
            <div class="row no-gutters">
              <div class="col-6">
                <div class="row no-gutters">
                  <toggle
                    v-for="attr in productAttributes"
                    :key="attr"
                    :value="attributeIsChecked(attr.inventoryItemAttributeID)"
                    :multiLabel="attr.inventoryItemAttributeDescription"
                    class="col-5 block-xs--xs-bottom"
                    @input="toggleAttribute(arguments[0], attr)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </line-item>

    <!-- <line-item v-if="imageServer" class="row no-gutters">
      <div class="col-12 col-sm-1">
        <div class="label block-sm--xs block-xs--xs-top">Image</div>
      </div>
      <div class="col-12 col-sm-11">
        <div class="block-sm--xs block-xs--xs-bottom">
          <upload @upload="handleUpload"/>
        </div>
        <div class="block-xs--xs-bottom">
          <image-block
            :images="images"
            :productName="value.inventoryItemName"
            @delete="deleteImageConfirmed"
          />
        </div>
      </div>
    </line-item>-->
    <line-item
      class="row no-gutters"
      v-if="existsOnProduct('imageV2') && userHasViewPermission('IMAGES')"
    >
      <StoreProductEditImageRow
        :images="productImages"
        :product="value"
        @inventoryItemImageNameChanged="inventoryItemImageNameChanged"
      />
    </line-item>
    <line-item>
      <div class="row no-gutters align-items-center">
        <ProductPlatformEdit
          v-model="value.platformInventory"
          :productId="value.id"
        />
      </div>
    </line-item>
  </form>
</template>

<script>
import {
    removeDups,
    parseTaxRate,
    dateStringToTime,
    timeStringToDate,
} from "helpers";
import clone from "clone";
import moment from "moment";
import { editWarningController } from "../mixins";
import FormInput from "components/cleverly/FormInput.vue";
import Upload from "components/Upload";
import SelectInput from "components/cleverly/SelectInput.vue";
import TextBox from "components/cleverly/TextBox.vue";
import Toggle from "components/cleverly/Toggle.vue";
import Tags from "components/cleverly/Tags.vue";
import LineItem from "components/line_item.vue";
import TinyExplorer from "components/tiny_explorer/explorer";
import VariationBuilder from "components/VariationBuilder";
import EditingModal from "components/editing_modal.vue";
import EditingModalHeaderSimple from "components/editing_modal_header_simple.vue";
import AddModGroup from "components/add_mod_group.vue";
import AddModSet from "components/add_mod_set.vue";
import EditWarning from "components/edit_warning";
import TextButton from "components/text_button";
import TimePicker from "components/TimePicker";
import ImageBlock from "components/image_block";
import ProductPlatformEdit from "components/product_platform_edit";
import { mapActions, mapState, mapGetters } from "vuex";
import StoreProductEditImageRow from "components/imagemanager/store_product_edit_image_row";
import { OMNI_SubSizeTransform } from "helpers/products";
import AccessMixin from "mixins/access_helper.js";
import TimeSelection from "components/product_edit/time_selection";

export default {
    name: "ProductForm",
    // Filters

    filters: {
        parseTaxRate,
    },

    // Components

    components: {
        TimeSelection,
        FormInput,
        Upload,
        SelectInput,
        TextBox,
        Toggle,
        Tags,
        LineItem,
        TinyExplorer,
        VariationBuilder,
        EditingModal,
        EditingModalHeaderSimple,
        AddModGroup,
        AddModSet,
        EditWarning,
        TextButton,
        TimePicker,
        ImageBlock,
        ProductPlatformEdit,
        StoreProductEditImageRow,
    },

    mixins: [editWarningController, AccessMixin],

    // Props

    props: {
        value: {
            type: Object,
            default: () => {},
        },
        modSets: {
            type: Array,
            default: () => [],
        },
        products: {
            type: Array,
            default: () => [],
        },
        imageServer: {
            type: String,
            default: () => [],
        },
        posMods: {
            type: Array,
            default: () => [],
        },
        posProducts: {
            type: Array,
            default: () => [],
        },
        integrated: {
            type: Boolean,
            default: false,
        },
        availability: {
            type: Array,
            default: () => [],
        },
    },

    // Data

    data() {
        const allDay = this.getTimeType();

        return {
            allDay,
            images: [],
            modModal: false,
            modGroupModal: false,
            modSetModal: false,
            editedModGroup: false,
            modSet: false,
            timeDays: "",
            activeDay: "sun",
            // Fixtures for data we don't have yet
            fixtureProduct: {
                type: "retail",
                healthInformation: "Lorem",
                additionalTurnaround: 1,
                tipping: true,
                platform: {
                    store: false,
                    mobile: true,
                    kiosk: true,
                    table: true,
                    delivery: false,
                },
                options: {
                    limitations: true,
                    featured: false,
                    employeeOnly: false,
                },
                images: [],
                promotion: {
                    enabled: false,
                    title: "Buy One Get One Half Off",
                    details: "Lo lo",
                    images: [],
                },
            },
            additionalTurnaroundOptions: [
                { text: "+ 0", value: 0 },
                { text: "+ 1", value: 1 },
                { text: "+ 2", value: 2 },
                { text: "+ 3", value: 3 },
                { text: "+ 4", value: 4 },
                { text: "+ 5", value: 5 },
                { text: "+ 6", value: 6 },
                { text: "+ 7", value: 7 },
                { text: "+ 8", value: 8 },
                { text: "+ 9", value: 9 },
                { text: "+ 10", value: 10 },
            ],
            platformOptions: {
                bEnabledKiosk: "Kiosk",
                bEnabledMobile: "Mobile",
                bEnabledResponsiveWeb: "Responsive Web",
                bEnabledOAT: "OAT",
                bEnabledDelivery: "Delivery",
            },
            modGroupCheckLabel: "Required Modifier Group?",
        };
    },

    // Computed

    computed: {
        timeHackOptions() {
            if (this.$route.params.store == 34190) {
                return [{ value: "1:01 PM", text: "1:01 PM" }];
            }
            if (this.$route.params.store == 34223) {
                return [{ value: "11:15 AM", text: "11:15 AM" }];
            }
            return undefined;
        },
        filteredModGroups() {
            return this.value.modGroups
                .filter((group) => {
                    return !group.bIsIncludedInModSet;
                })
                .sort((a, b) => Number(a.displayOrder) - Number(b.displayOrder));
        },
        supportedPlatforms() {
            return Object.entries(this.platformOptions).map((pair) => pair[0]); //to keys
            //.filter(el => this.existsOnProduct(el))
        },
        sortedSubs: {
            get() {
                return this.value.inventoryItemSubs
                    .map((el) => {
                        if (this.pos == "omnivore") {
                            return OMNI_SubSizeTransform(
                                el,
                                this.posProducts,
                                this.value.pos_productID
                            );
                        } else if (this.pos == "vvn") {
                            return {
                                ...el,
                                pos_productID: el.pos_sizeID,
                            };
                        }
                        return el;
                    })
                    .sort((a, b) => {
                        const aPrice = parseFloat(a.price || a.cost);
                        const bPrice = parseFloat(b.price || b.cost);

                        if (aPrice > bPrice) {
                            return 1;
                        }

                        if (aPrice < bPrice) {
                            return -1;
                        }

                        return 0;
                    });
            },
            set(value) {
                this.value.inventoryItemSubs = value;
            },
        },

        category: {
            get() {
                return this.value.inventoryTitles[0].inventoryTitleID;
            },
            set(val) {
                const category = clone(this.productCategories).find(
                    (el) => el.inventoryTitleID === val
                );
                
                const result = clone(category)

                result.inventorySubTitles =                         
                    [{
                        inventorySubTitleID: -1,
                        inventorySubTitleDescription: "",
                    }]

                this.$set(this.value.inventoryTitles, 0, result)
            },
        },
        subCategory: {
            get() {
                if (this.value.inventoryTitles[0].inventorySubTitles[0] == undefined) return undefined

                return  this.value.inventoryTitles[0].inventorySubTitles[0].inventorySubTitleID;
            },
            set(val) {
                if (val && val != "") {
                    const category = this.productCategories.find(
                        (el) => el.inventoryTitleID === this.category
                    );
                    const subCategory = category.inventorySubTitles.find(x => x.inventorySubTitleID === val);
                    const result = clone(this.value.inventoryTitles[0])
                    result.inventorySubTitles[0] = subCategory
                    this.$set(this.value.inventoryTitles, 0, result)
                }
                else if (val == "") {
                    const result = clone(this.value.inventoryTitles[0])
                    result.inventorySubTitles[0] = 
                        {
                            inventorySubTitleID: -1,
                            inventorySubTitleDescription: "",
                        }
                    this.$set(this.value.inventoryTitles, 0, result)
                }

            }
        },
        productImages() {
            return {
                ...this.value.imageV2,
                img_asset_384x216: this.value.inventoryItemImageName,
            };
        },
        groups() {
            return this.modSets.reduce((sum, next) => {
                return sum.concat(next.children);
            }, []);
        },

        categoryOptions() {
            return this.productCategories.map((category) => {
                return {
                    text: category.inventoryTitleDescription,
                    value: category.inventoryTitleID,
                };
            });
        },
        subCategoryOptions() {
            const category = this.productCategories.find(
                (el) => el.inventoryTitleID === this.category
            );
            if (category == undefined || category.inventorySubTitles == undefined || category.inventorySubTitles.length == 0) return []
            return category.inventorySubTitles.map(x => ({
                text: x.inventorySubTitleDescription,
                value: x.inventorySubTitleID,
            }));
        },
        availableTags() {
            let rStore = parseInt(this.$route.params.store);
            let enabled = [
                34097, 34222, 33730, 34144, 34135, 500, 35992, 30035, 34329, 34187,
                36205, 33726, 30010, 28006, 36277, 34348, 36209, 30036, 36208, 37392,
                37378, 36203, 34145, 33704, 34140, 34139, 26732, 26439, 34466, 34187,
                34464, 27659, 33707, 36043, 36804, 35622, 37505, 37507, 37509, 36734,
                35623, 37377, 37452, 37449, 37508, 34336, 34476, 37451, 37503, 37504,
                37506, 37510, 37450, 33616, 37573, 34131, 35196, 37398, 35925, 37744,
                37669, 37784, 37619, 37783, 37786, 37506, 37858, 37851, 37743, 37684,
                36192, 35924
            ];
            if (enabled.includes(rStore)) {
                return [
                    "Sugar Free",
                    "Organic",
                    "Vegetarian",
                    "Vegan",
                    "Kosher",
                    "Healthy",
                    "Halal",
                    "Gluten Free",
                    "Eggs",
                    "Wheat",
                    "Dairy",
                    "Peanuts",
                    "Tree Nuts",
                    "Soy",
                    "Chili"
                ];
            }

            if (
                this.store.alcoholMealLimitEnabled !== undefined &&
        this.store.alcoholMealLimitEnabled === true
            ) {
                return ["Substantial Meal"];
            } else {
                return [];
            }
        },
        modPermissions() {
            if (!this.canEdit) {
                return ["viewGrandChildren", "selectGrandChild"];
            }

            return [
                "viewGrandChildren",
                "editChild",
                "removeChild",
                "removeGrandChild",
                "selectGrandChild",
                "checkChild",
            ];
        },
        ...mapState("ImageUploadStore", {
            uploadedImageForProduct(state) {
                return state.uploadedImages[this.value.id];
            },
        }),
        ...mapGetters(["pos", "store", "productCategories"]),
    },

    // Watch

    watch: {
        value: {
            handler(value) {
                this.$emit("productUpdated", value);
            },
            deep: true,
        },
        "value.inventoryItemID"() {
            this.setImages();
        },
    },

    created() {
        this.setInitialModSets();

        this.setImages();
    },

    // Methods

    methods: {
        getTimeType() {
            const startTime = moment.utc(this.value.startTimeLocal).format("h:mm A");
            const endTime = moment.utc(this.value.endTimeLocal).format("h:mm A");

            if (startTime === "12:00 AM" && endTime === "11:59 PM") {
                return "all-day";
            }

            return "custom";
        },
        existsOnProduct(prop) {
            return this.value[prop] !== undefined;
        },

        attributeIsChecked(id) {
            let isChecked = false;
            this.value.inventoryItemAttributes.forEach((attr) => {
                if (attr.inventoryItemAttributeID === id) {
                    isChecked = true;
                }
            });
            return isChecked;
        },

        toggleAttribute(checked, attr) {
            if (checked) {
                this.value.inventoryItemAttributes.push(attr);
            } else {
                this.value.inventoryItemAttributes.forEach((attr2, i) => {
                    if (
                        attr2.inventoryItemAttributeID === attr.inventoryItemAttributeID
                    ) {
                        this.value.inventoryItemAttributes.splice(i, 1);
                    }
                });
            }
        },

        addModSet(modSet) {
            const selectedSet = clone(
                this.modSets.find((set) => set.id === modSet.id)
            );

            if (this.value.modSets.find((set) => set.id === selectedSet.id)) {
                this.toast("You have already selected that mod set.");
                return true;
            }

            this.value.modSets = this.value.modSets.concat([modSet]);
            this.modSetModal = false;
        },

        removeModSet(modSet) {
            this.$emit("removeModSet", modSet);

            const newSets = this.value.modSets.filter((set) => {
                return set.id !== modSet.id;
            });

            const groups = this.value.inventoryMainOptionChoice.Options;
            const setGroups = modSet.children;

            const newGroups = groups.filter((group) => {
                return !setGroups.find(
                    (setGroup) => setGroup.optionID === group.optionID
                );
            });

            this.value.inventoryMainOptionChoice.Options = newGroups;
            this.value.modSets = newSets;
        },

        handleModGroupEdit(modGroup) {
            const action = () => {
                this.editedModGroup = modGroup;

                this.toggleModGroupModal();
            };

            const optionId = modGroup.optionID;
            const effected = this.getModGroupAssociatedProducts(optionId);

            if (!effected.length) {
                return action();
            }

            this.generateWarning(
                "mod group",
                removeDups(effected, "inventoryItemID"),
                () => {
                    action();
                }
            );
        },

        toggleModGroupModal() {
            if (this.modGroupModal) {
                this.modGroupModal = false;
                this.editedModGroup = false;
            } else {
                this.modGroupModal = true;
            }
        },

        addModGroup(group) {
            const groups = this.value.modGroups;
            const { id } = group;
            const hasId = id !== "";
            const alreadyExists = groups.find((group) => group.id === id);

            if (!hasId || (hasId && !alreadyExists) || this.editedModGroup) {
                const fullGroup = Object.assign(group, {
                    children: this.reorderMods(group.children),
                });

                if (this.editedModGroup) {
                    const newGroups = groups.map((el) => {
                        if (el.id === fullGroup.id) {
                            return Object.assign(el, fullGroup);
                        }

                        return el;
                    });

                    this.value.modGroups = newGroups;
                } else {
                    this.value.modGroups = groups.concat([fullGroup]);
                }

                this.modGroupModal = false;
                this.editedModGroup = false;
            } else {
                this.toast("You have already added that mod group.");
            }

            this.$emit("saveModGroup", group);
        },

        handleRemoveMod(...args) {
            const [mod] = args;
            const optionId = mod.id;
            const effected = this.getModGroupAssociatedProducts(optionId);

            if (!effected.length) {
                return this.$emit("removeMod", ...args);
            }

            this.generateWarning(
                "mod group",
                removeDups(effected, "inventoryItemID"),
                () => {
                    this.$emit("removeMod", ...args);
                }
            );
        },

        handleRemoveModGroup(...args) {
            const [modGroup] = args;
            const optionId = modGroup.optionID;
            const effected = this.getModGroupAssociatedProducts(optionId);

            if (!effected.length) {
                return this.$emit("removeModGroup", ...args);
            }

            this.generateWarning(
                "mod group",
                removeDups(effected, "inventoryItemID"),
                () => {
                    this.$emit("removeModGroup", ...args);
                }
            );
        },

        handleCheckModGroup({ item, value }) {
            this.$emit("onToggleModGroup", item);

            // this.value.modGroups = this.value.modGroups
            //     .map(el => {
            //         if (el.title === item.title) {
            //             return Object.assign(item, {required: value})
            //         }

            //         return el
            //     })
        },

        handleReorderModGroups(items) {
            this.value.modGroups = items.map((el, i) => {
                return {
                    ...el,
                    children: this.reorderMods(el.children),
                    displayOrder: i + 1,
                };
            });
        },

        getModOrderStart(mods) {
            const sorted = []
                .concat(mods)
                .sort((a, b) => Number(a.order) - Number(b.order));

            return sorted.length ? Number(sorted[0].order) : 1;
        },

        reorderMods(mods) {
            const start = this.getModOrderStart(mods);

            return mods.map((el, i) => {
                return { ...el, order: String(i + start) };
            });
        },

        getModGroupAssociatedProducts(optionId) {
            return this.modSets
                .filter((modSet) => {
                    return modSet.children.find((el) => el.optionID === optionId);
                })
                .reduce((sum, next) => {
                    return sum.concat(next.inventoryItemIDs);
                }, [])
                .map((productId) => {
                    return this.products.find((el) => el.inventoryItemID === productId);
                })
                .filter((el) => el !== undefined);
        },

        setInitialModSets() {
            const modSets = this.modSets.filter((set) => {
                return this.value.modGroups.find((group) => {
                    return String(group.modSetID) === String(set.modSetID);
                });
            });

            this.value.modSets = removeDups(modSets, "modSetID");
        },

        handleUpload(payload) {
            this.images = [payload.imageBase64];
            this.addUploadedImageForProduct({
                productId: this.value.id,
                imageBase64: payload.imageBase64,
            });
            this.$emit("uploadImage", payload);
        },

        deleteImageConfirmed(payload) {
            this.value.inventoryItemImageName = "";
            this.images = [];
            this.removeUploadedImageForProduct(this.value.id);
        },

        setImages() {
            if (this.uploadedImageForProduct) {
                this.images = [this.uploadedImageForProduct];
                return;
            }
            if (this.value.inventoryItemImageName.length === "") return [];
            if (this.imageServer) {
                this.images = [this.imageServer + this.value.inventoryItemImageName];
            }
        },
        modGroupGrandChildTitle(item) {
            let title = `${item.title} | `;

            if (item.id) {
                title += `${item.id} | `;
            }
            if (item.pos_modifierID) {
                title += `${item.pos_modifierID} | `;
            }

            if (
                item.optionCostDisplay != undefined &&
        item.optionCostDisplay.length > 0
            ) {
                title += `${item.optionCostDisplay}`;
            } else if (
                item.choiceCostDisplay != undefined &&
        item.choiceCostDisplay.length > 0
            ) {
                title += `${item.choiceCostDisplay}`;
            }

            return title;
        },
        inventoryItemImageNameChanged(fileName) {
            this.value.inventoryItemImageName = fileName;
        },
        handleVariationsChange(variations) {
            this.value.inventoryItemSubs = variations;
        },
        handleVariationDelete(variation) {
            if (variation.inventoryItemSubID) {
                this.$emit("removeSub", variation);
            }
        },
        removeDups,
        ...mapActions("ImageUploadStore", [
            "addUploadedImageForProduct",
            "removeUploadedImageForProduct",
        ]),
    },
};
</script>

<style lang="scss">
@import "~scss/variables";
@import "~scss/mixins";

.platform-toggle {
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-form {
  .category-temp {
    margin-top: 10px;
  }
  textarea {
    @include below(md) {
      width: 100%;
    }
  }

  input {
    @include below(xs) {
      width: 100%;
    }
  }

  .block-label > label {
    @include roboto-bold;
    display: block;
    font-size: fz(sm);
    color: $grey_mid_1;
    margin: spacing(xs) 0px;
    width: auto;
  }

  & > .line-item {
    padding: 0px 30px;
    margin-right: 0;
  }

  .sub-label {
    label {
      @include roboto;
      padding-right: spacing(xs);
    }
  }

  .group-inputs {
    display: inline-block;
    vertical-align: top;
  }

  .taxes {
    width: 100%;
  }

  .mod-sets {
    margin-left: 185px;
  }

  .add-mod-group-button {
    display: block;
    color: $brand_color;
  }

  .label-offset {
    margin-left: 155px;
    &.line-item {
      margin-right: -30px;
      &:before {
        display: none;
      }
    }
  }

  .adjacent-form {
    margin-right: 10px;
  }

  &-control {
    input {
      @include above(md) {
        width: 562px;
      }
    }
  }
}

.product-form-time-pickers {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  padding-top: 10px;

  & > * {
    flex: 1;
    margin-left: 20px;

    @include below(sm) {
      width: 100%;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.product-form-time-picker {
  display: flex;
  // min-width: 250px;
  margin-bottom: 5px;
  & > * {
    flex: 1;
  }

  &__picker {
    margin-left: 10px;
    min-width: 250px;

    select {
      max-width: 100% !important;
    }
  }

  &__label {
    flex-grow: 0 !important;
  }

  @include below(sm) {
    &__label {
      max-width: 50px;
    }
  }
}
</style>
