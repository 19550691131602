var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "variation-builder block-xs--md-bottom row no-gutters" },
    [
      _vm.value.length
        ? [
            _c("div", { staticClass: "variation-builder__table col-12" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "line" },
                  [
                    _vm.bespoke
                      ? _c("div", {
                          staticClass: "variation-builder__table-data",
                        })
                      : _vm._e(),
                    _vm._l(_vm.struct, function (prop) {
                      return _c(
                        "div",
                        {
                          key: prop.display,
                          staticClass: "variation-builder__table-data",
                          style: { flex: prop.size || 1 },
                        },
                        [_vm._v(" " + _vm._s(prop.display) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                { ref: "sortableList" },
                _vm._l(_vm.localItems, function (variation, i) {
                  return _c(
                    "div",
                    { key: variation.inventoryItemSubID, staticClass: "line" },
                    [
                      _vm.bespoke
                        ? _c(
                            "div",
                            { staticClass: "variation-builder__table-data" },
                            [_c("div", { staticClass: "icon icon--handle" })]
                          )
                        : _vm._e(),
                      _vm._l(_vm.struct, function (prop) {
                        return _c(
                          "div",
                          {
                            key: prop.name,
                            staticClass: "variation-builder__table-data",
                            class: { small: _vm.small },
                            style: { flex: prop.size || 1 },
                          },
                          [
                            prop.name
                              ? [
                                  _c("form-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled:
                                        _vm.disabled &&
                                        !_vm.canForceEdit(prop.name),
                                      bare: true,
                                      full: true,
                                      spellcheck: "true",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.onNameChanged(i)
                                      },
                                    },
                                    model: {
                                      value: variation[prop.name],
                                      callback: function ($$v) {
                                        _vm.$set(variation, prop.name, $$v)
                                      },
                                      expression: "variation[prop.name]",
                                    },
                                  }),
                                ]
                              : [
                                  _c(prop.render(variation, i), {
                                    tag: "component",
                                  }),
                                ],
                          ],
                          2
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "variation-builder__delete-container" },
                        [
                          _vm.value.length > 1 || !_vm.integrated
                            ? _c("div", {
                                staticClass:
                                  "variation-builder__button icon icon--remove block-xs--xs-left",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeVariation(variation)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ],
                    2
                  )
                }),
                0
              ),
            ]),
          ]
        : [
            _c("p", [
              _vm._v(
                " There does not appear to be any " +
                  _vm._s(_vm.entityName) +
                  "s for this item. Click below to add one. "
              ),
            ]),
          ],
      _c(
        "text-button",
        {
          staticClass: "block-xs--md-top col-12",
          attrs: { type: "add" },
          nativeOn: {
            click: function ($event) {
              return _vm.addVariation.apply(null, arguments)
            },
          },
        },
        [_vm._v("Add " + _vm._s(_vm.entityName))]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }