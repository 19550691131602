var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "editing-modal__header height-sm--8 d-flex justify-content-between align-items-center",
      },
      [
        _vm.manualTitle
          ? [
              _c("h2", { staticClass: "editing-modal__title" }, [
                _vm._v(" " + _vm._s(_vm.manualTitle) + " "),
              ]),
            ]
          : [
              _c("h2", { staticClass: "editing-modal__title" }, [
                _vm._v(
                  " Editing " +
                    _vm._s(_vm.items.length) +
                    " item" +
                    _vm._s(_vm.items.length > 1 ? "s" : "") +
                    " "
                ),
              ]),
            ],
        _c(
          "div",
          { staticClass: "editing-modal__actions" },
          [
            _c("action-button-set", {
              attrs: { cancel: _vm.cancelAction, proceed: _vm.saveAction },
            }),
          ],
          1
        ),
      ],
      2
    ),
    _c("div", { staticClass: "editing-modal__header-edit-list" }, [
      _c(
        "ul",
        _vm._l(_vm.items, function (item) {
          return _c("li", [
            _c("span", [_vm._v(_vm._s(item.id))]),
            _vm._v("   "),
            _c("span", [_vm._v(_vm._s(item[_vm.displayProp]))]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }